<template>
    <div id="login">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="login-content d-flex row">
                        <div class="col-md-6 pr-0 order-1 order-md-0">
                            <div v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/banner-login.png') + ')' }" class="login-banner d-flex">
                                <div class="align-self-center">
                                    Membangun kebersamaan  dalam menghasilkan Co-Creation dan Collaboration
                                </div>
                            </div>
                        </div>
                        <div class="login-form col-md-6 order-0 order-md-1">
                            <h3>Masuk</h3>
                            <p>Silakan masukkan email/username dan password yang telah terdaftar</p>
                            <br><br>
                            <form @submit.prevent="actionLogin()">
                                <input type="text" v-model="username" name="username" class="form-control" placeholder="Email or Username">
                                <input type="password" v-model="password" name="password" class="form-control" placeholder="Password">
                                <router-link to="/forgot"><b>Lupa Kata Sandi?</b></router-link><br><br><br>
                                <button class="btn btn-primary fullsize-btn">Login</button>
                            </form><br><br>
                            <center>Belum punya akun? <router-link to="/register"><b>Daftar</b></router-link></center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'Login',
        data () {
            return {
                username: '',
                password: ''
            }
        },
        methods: {
            async actionLogin () {
                const response = await axios.post('login', {
                    username: this.username,
                    password: this.password
                })
                if (response.data.status) {
                    localStorage.setItem('token', response.data.user.token)
                    localStorage.setItem('verify', response.data.user.email_verification)
                    this.$router.go('/app/feed')
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            }
        }
    }
</script>
